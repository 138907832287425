<template>
  <div class="enterpriseInvoice">
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      @select-all="handleSelectionAll"
      @select="handleSelection"
    >
      <template slot="toolbar">
        <div class="col-auto mr-2">
          <el-checkbox
            v-model="allPage"
            @change="isChangeAllPage"
          >全选所有页</el-checkbox>
        </div>
        <el-form-item
          label="审核状态"
          class="col-auto"
        >
          <el-select
            style="width: 100px"
            size="small"
            v-model="params.status"
          >
            <el-option
              v-for="item in auditStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="发票类型"
          class="col-auto"
        >
          <el-select
            size="small"
            style="width: 130px"
            v-model="params.type"
          >
            <el-option
              v-for="item in invoiceType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="提交时间">
          <el-date-picker
            style="width: 220px"
            v-model="params.time_range"
            type="daterange"
            align="center"
            :editable="false"
            unlink-panels
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            size="small"
            :picker-options="{ disabledDate(time) { return time.getTime() > Date.now() }, shortcuts: MixinPickerShortcuts }"
          ></el-date-picker>
        </el-form-item>

        <el-form-item
          label=""
          class="col-auto"
        >
          <el-input
            size="small"
            placeholder="企业名称"
            style="width: 150px;"
            v-model="params.apply_name"
            clearable
          ></el-input>

        </el-form-item>
        <el-button
          type="primary"
          size="small"
          @click="advancedSearchEvent"
        >搜索</el-button>
        <el-button
          type="primary"
          size="small"
          @click="handleAudit([42])"
        >批量审核</el-button>
        <div class="col"></div>
        <br>

      </template>

      <template slot="table-columns">
        <el-table-column
          type="selection"
          width="40"
        ></el-table-column>
        <el-table-column
          label="提交批次号"
          prop="batch_sn"
          width="150"
        ></el-table-column>
        <el-table-column
          label="发票类型"
          prop="type"
          width="110"
        >
          <template slot-scope="scope">{{ scope.row.type === 1 ? '商品订单发票' : (scope.row.type === 2 ? '服务订单发票' : '运费发票') }}</template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="申请时间"
          width="150"
        >
          <template slot-scope="scope">{{scope.row.create_time|unixToDate}}</template>
        </el-table-column>
        <el-table-column
          label="申请企业"
          prop="apply_name"
          min-width="200"
        ></el-table-column>
        <el-table-column
          label="联系方式"
          width="120"
          prop="receive_phone"
        ></el-table-column>
        <el-table-column
          label="审核状态"
          prop=""
          width="80"
        >
          <template slot-scope="scope">
            {{scope.row.status==-1?'已拒绝':scope.row.status==0?'待审核':scope.row.status==1?'已通过': scope.row.status===3 ? '已成功' : '已经邮寄'}}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status==0"
              size="mini"
              type="primary"
              @click="handleAudit([scope.row.history_id])"
            >审核</el-button>
            <el-button
              v-if="scope.row.status==1"
              size="mini"
              type="primary"
              @click="handleExpress(scope.row.history_id)"
            >邮寄</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="check(scope.row.history_id, scope.row.type)"
            >查看</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :page-size="tableData.page_size"
        :layout="MixinTableLayout"
        :total="tableData.data_total"
        background
      ></el-pagination>
    </en-table-layout>
    <el-dialog-x-dialog
      :title="dialogAuditTitle"
      :visible.sync="dialogAuditVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="auditForm"
        :rules="auditRules"
        ref="auditForm"
        label-width="100px"
      >
        <!--是否通过=-->
        <el-form-item
          label="是否通过"
          prop="state"
        >
          <el-radio-group v-model="auditForm.state">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="备注信息"
          prop="reason"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入审核备注(120字以内)"
            :maxlength="120"
            v-model="auditForm.reason"
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button
            @click="dialogAuditVisible = false"
            size="small"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="submitAuditForm"
            size="small"
          >确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog-x-dialog>
    <el-dialog-x-dialog
      title="发票邮寄"
      :visible.sync="dialogExpressVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="expressForm"
        :rules="expressRules"
        ref="expressForm"
        label-width="100px"
      >
        <!--是否通过=-->
        <el-form-item
          label="物流公司"
          prop="logi_id"
        >
          <el-select
            style="width: 160px;"
            v-model="expressForm.logi_id"
            @change="handleExpressCompanyChange"
          >
            <el-option
              v-for="item in expressCompany"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="物流单号"
          prop="delivery_no"
        >
          <el-input
            type="text"
            placeholder="请输入物流单号"
            :maxlength="120"
            v-model="expressForm.delivery_no"
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button
            @click="dialogExpressVisible = false"
            size="small"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="submitExpressForm"
            size="small"
          >确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_Invoice from "@/api/invoice";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "enterpriseInvoice",
  components: { EnTableLayout },
  data() {
    return {
      tableData: {},
      params: {
        page_no: 1,
        page_size: 20,
        status: "",
        type: "",
        apply_name: "",
      },
      loading: false,
      enterpriseName: "",
      value: "",
      auditStatus: [
        { label: "全部", value: "" },
        { label: "已拒绝", value: -1 },
        { label: "待审核", value: 0 },
        { label: "已通过", value: 1 },
        { label: "已经邮寄", value: 2 },
      ],
      invoiceType: [
        { label: "全部", value: "" },
        { label: "商品订单发票", value: 1 },
        { label: "服务订单发票", value: 2 },
        { label: "运费发票", value: 3 },
      ],
      thisPage: false,
      allPage: false,
      idsList: [],
      //审核相关字段
      dialogAuditVisible: false,
      dialogAuditTitle: "企业发票审核",
      auditForm: {
        reason: "",
        state: 1, //1是;0否
        is_all: 0, //1是0未通过
      },
      auditRules: {
        state: [
          { required: false, message: "请选择是否通过！", trigger: "blur" },
        ],
        message: [
          { required: false, message: "请输入审核备注！", trigger: "blur" },
        ],
      },
      //邮寄相关字段
      dialogExpressVisible: false,
      expressForm: {
        logi_id: "",
        logi_name: "",
        delivery_no: "",
        history_id: "",
      },
      expressRules: {
        logi_id: [
          { required: true, message: "请选择物流公司！", trigger: "change" },
        ],
        delivery_no: [
          { required: true, message: "请填写物流单号！", trigger: "blur" },
        ],
      },
      expressCompany: [],
    };
  },
  watch: {
    "auditForm.state": function (newVal) {
      this.auditRules.message[0].required = newVal === 0;
    },
  },
  mounted() {
    this.GET_List();
    this.getExpressCompany();
  },
  activated() {},
  methods: {
    isChangeThisPage() {
      // this.thisPage = true
      this.allPage = false;
      if (this.thisPage) {
        this.$refs.multipleTable.$refs.table.toggleAllSelection();
      } else {
        this.$refs.multipleTable.$refs.table.clearSelection();
      }
    },
    isChangeAllPage() {
      this.thisPage = false;
      // this.allPage = true
      this.$refs.multipleTable.$refs.table.clearSelection();
    },
    handleSelectionChange(val) {
      if (val.length == this.tableData.data.length) {
        this.thisPage = true;
      } else {
        this.thisPage = false;
      }
      let ids = [];
      val.forEach((item) => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
    handleSelectionAll() {
      this.allPage = false;
    },
    handleSelection() {
      this.allPage = false;
    },
    GET_List() {
      API_Invoice.getAuditList(this.params).then((res) => {
        this.tableData = res;
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },
    advancedSearchEvent() {
      this.params = {
        ...this.params,
      };
      delete this.params.start_time;
      delete this.params.end_time;
      const { time_range } = this.params;
      if (time_range) {
        this.params.start_time = parseInt(time_range[0] / 1000);
        this.params.end_time = parseInt(time_range[1] / 1000 + 96399);
      }
      delete this.params.time_range;
      this.params.page_no = 1;
      this.GET_List();
    },
    check(id, type) {
      this.$router.push({
        path: "/finance/invoice/auditHistoryDetail",
        query: {
          id,
          type,
        },
      });
    },
    /** 审核 */
    handleAudit(ids) {
      if (ids.length || this.idsList.length) {
        this.auditForm = {
          reason: "",
          state: 1,
        };
        if (this.allPage) {
          this.auditForm.is_all = 1;
          delete this.auditForm.history_ids;
        } else {
          this.auditForm.is_all = 0;
          if (ids.length) {
            this.auditForm.history_ids = ids;
          } else {
            this.auditForm.history_ids = this.idsList;
          }
        }
        this.dialogAuditVisible = true;
      } else {
        this.$message.error("请勾选待审核的选项");
      }
    },
    handleExpress(id) {
      this.expressForm.history_id = id;
      this.dialogExpressVisible = true;
    },
    /** 审核表单提交 */
    submitAuditForm() {
      this.$refs["auditForm"].validate((valid) => {
        if (valid) {
          API_Invoice.aduditHistory(this.auditForm).then((response) => {
            this.dialogAuditVisible = false;
            this.$message.success("审核完成！");
            this.GET_List();
          });
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },
    getExpressCompany() {
      API_Invoice.getExpressCompany({ page_no: 1, page_size: 9999 }).then(
        (res) => {
          this.expressCompany = res.data;
        }
      );
    },
    handleExpressCompanyChange(v) {
      this.expressCompany.forEach((item, index) => {
        if (item.id == v) {
          this.expressForm.logi_name = item.name;
        }
      });
    },
    submitExpressForm() {
      this.$refs["expressForm"].validate((valid) => {
        if (valid) {
          API_Invoice.postReceiptByHistoryId(
            this.expressForm.history_id,
            this.expressForm
          ).then((res) => {
            this.$message.success("邮寄成功");
            this.GET_List();
            this.$refs["expressForm"].resetFields();
            this.expressForm.logi_name = "";
            this.expressForm.history_id = "";
            this.dialogExpressVisible = false;
          });
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },
  },
};
</script>

